import { ExtendedComponentInfo } from 'hcosmos-api-data'
import { defineStore, storeToRefs } from 'pinia'
import { ref } from 'vue'
import { pinia } from '..'
import { createFetchArms } from './fetchArms'

export const useArmStore = defineStore(
  'armStore',
  () => {
    const unattachedArms = ref<ExtendedComponentInfo[]>([])

    return {
      unattachedArms,
      fetchArms: createFetchArms(unattachedArms),
    }
  },
  {
    dummyValue: {
      fetchArms: async (store) => {
        store.unattachedArms = []
        return []
      },
    },
    authenticated: {
      fetchArms: true,
    },
  },
)

export const injectArms = () => {
  return useArmStore(pinia).fetchArms()
}

export const injectArmRefs = () => storeToRefs(useArmStore(pinia))