import { systemTableDummyValues } from '@/data/systems'
import { RobotSystem } from '@/interfaces'
import { defineStore, storeToRefs } from 'pinia'
import { ref } from 'vue'
import { pinia } from '..'
import { createFetchSystems, setRevalidationFlag } from './fetchSystems'

export const useFleetStore = defineStore(
  'fleetStore',
  () => {
    const systems = ref<RobotSystem[]>([])

    return {
      systems,
      fetchSystems: createFetchSystems(systems),
      setRevalidationFlag: setRevalidationFlag,
    }
  },
  {
    dummyValue: {
      fetchSystems: async (store) => {
        const systems = await systemTableDummyValues()
        store.systems = systems
        return systems
      },
    },
    authenticated: {
      fetchSystems: true,
    },
  },
)

export const injectSystems = () => {
  return useFleetStore(pinia).fetchSystems()
}

export const injectFleetRefs = () => storeToRefs(useFleetStore(pinia))
