import i18n from '@/locales/i18n'
import { alphaNum, email, helpers, maxLength, minLength, numeric, required, requiredIf } from '@vuelidate/validators'
import { computed } from 'vue'

const { t } = i18n.global

export const formCreateServiceIssueTicketRules = (requireHorstFxVersion = false) => computed(() => {
  let rules = {
    summary: {
      message: helpers.withMessage(
        `${t('messages.modals.createServiceTicket.summary')} ${t('messages.toast.required')}`,
        required,
      ),
    },
    robot: {
      message: helpers.withMessage(
        `${t('messages.modals.createServiceTicket.robot')} ${t('messages.toast.required')}`,
        required,
      ),
    },
    description: {
      message: helpers.withMessage(
        `${t('messages.modals.createServiceTicket.description')} ${t('messages.toast.required')}`,
        required,
      ),
    },
    horstFxVersion: {
      message: helpers.withMessage(
        `horstFX-Version ${t('messages.toast.required')}`,
        requiredIf(() => requireHorstFxVersion),
      ),
    },
    errorConfirmation: {
      message: helpers.withMessage(
        `${t('messages.modals.createServiceTicket.acknowledge')} ${t('messages.toast.required')}`,
        required,
      ),
    },
    errorTime: {
      message: helpers.withMessage(
        `${t('messages.modals.createServiceTicket.time')} ${t('messages.toast.required')}`,
        required,
      ),
    },
    errorFrequency: {
      message: helpers.withMessage(
        `${t('messages.modals.createServiceTicket.frequency')} ${t('messages.toast.required')}`,
        required,
      ),
    },
    errorReplication: {
      message: helpers.withMessage(
        `${t('messages.modals.createServiceTicket.position')} ${t('messages.toast.required')}`,
        required,
      ),
    },
    internetConfirmation: {
      message: helpers.withMessage(
        `${t('messages.modals.createServiceTicket.isThereInternet')} ${t('messages.toast.required')}`,
        required,
      ),
    },
    contactName: {
      message: helpers.withMessage(
        `${t('messages.modals.createServiceTicket.contactName')} ${t('messages.toast.required')}`,
        required,
      ),
    },
    contactEmail: {
      message: helpers.withMessage(
        `${t('messages.modals.createServiceTicket.contactEmail')} ${t('messages.toast.required')}`,
        required,
      ),
      valid: helpers.withMessage(t('messages.toast.validEmail'), email),
    },
    contactPhone: {
      message: helpers.withMessage(
        `${t('messages.modals.createServiceTicket.contactPhone')} ${t('messages.toast.required')}`,
        required,
      ),
      valid: helpers.withMessage(t('messages.toast.validPhone'), numeric),
      minLength: helpers.withMessage(t('messages.toast.validPhone'), minLength(5)),
      maxLength: helpers.withMessage(t('messages.toast.validPhone'), maxLength(13)),
    },
    robotStreet: {
      message: helpers.withMessage(
        `${t('messages.modals.createServiceTicket.street')} ${t('messages.toast.required')}`,
        required,
      ),
    },
    robotStreetNumber: {
      message: helpers.withMessage(
        `${t('messages.modals.createServiceTicket.houseNumber')} ${t('messages.toast.required')}`,
        required,
      ),
      valid: helpers.withMessage(t('messages.toast.validHouseNumber'), alphaNum),
    },
    robotZip: {
      message: helpers.withMessage(
        `${t('messages.modals.createServiceTicket.postalCode')} ${t('messages.toast.required')}`,
        required,
      ),
      valid: helpers.withMessage(t('messages.toast.validPostalCode'), numeric),
    },
    robotCity: {
      message: helpers.withMessage(
        `${t('messages.modals.createServiceTicket.location')} ${t('messages.toast.required')}`,
        required,
      ),
    }
  }

  return rules
})
