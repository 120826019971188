import { RobotSystem } from '@/interfaces'
import { defineStore, Store, storeToRefs } from 'pinia'
import { systemDummy } from '@/data/systems'
import { computed, ref } from 'vue'
import { pinia } from '..'
import {
  createBundleSystem,
  createSaveProductionSystem,
  createSaveControlOnlySystem,
  createFetchSystemBySerial,
  createFetchSystemById,
  createReleaseLicenses,
  createUpdateUserSystemAttribute,
  createSendSystemNotification
} from '@/stores/system'
import { createShipSystem } from './shipSystem'

export const useSystemStore = defineStore(
  'systemStore',
  () => {
    const selectedSystem = ref<null | RobotSystem>(null)
    const selectedSystemNickname = computed(() => selectedSystem.value?.attributes.nickname)
    const selectedSystemId = computed(() => selectedSystem.value?.id)

    return {
      selectedSystem,
      selectedSystemNickname,
      selectedSystemId,
      fetchSystemById: createFetchSystemById(selectedSystem),
      fetchSystemBySerial: createFetchSystemBySerial(selectedSystem),
      shipSystem: createShipSystem(),
      saveProductionSystem: createSaveProductionSystem(),
      saveControlOnlySystem: createSaveControlOnlySystem(),
      bundleSystem: createBundleSystem(),
      releaseLicenses: createReleaseLicenses(),
      updateUserSystemAttribute: createUpdateUserSystemAttribute(),
      sendSystemNotification: createSendSystemNotification()
    }
  },
  {
    dummyValue: {
      fetchSystemById: async (store, id: string) => {
        store.selectedSystem = systemDummy(id)
      },
      fetchSystemBySerial: async (store, id: string) => {
        const system = systemDummy(id)
        store.selectedSystem = system
      },
      shipSystem: async (store: Store, system: RobotSystem) => ({
        username: system.serialNumber.toLocaleUpperCase() + '@dummyrobots.fruitcore.de',
        password: 'DUMMY DUMMY',
        uuid: '1234',
      }),
    },
    authenticated: {
      fetchSystemById: true,
      fetchSystemBySerial: true,
      shipSystem: true,
      saveProductionSystem: true,
      saveControlOnlySystem: true,
      bundleSystem: true,
      releaseLicenses: true,
      updateUserSystemAttribute: true,
      sendSystemNotification: true
    },
  },
)

export type SystemStore = typeof useSystemStore

export const injectSystemRefs = () => storeToRefs(useSystemStore(pinia))

export const injectSystem = (idOrSerial: string) => {
  const store = useSystemStore(pinia)

  if (idOrSerial.length === 36) {
    console.log('by id')
    return store.fetchSystemById(idOrSerial)
  } else {
    console.log('by serial')
    return store.fetchSystemBySerial(idOrSerial)
  }
}
