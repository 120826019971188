import { uiOptions } from '@/config'
import { DataMode } from '@/enums'
import { createPinia, PiniaPlugin } from 'pinia'
import { useAuthStore } from './auth/authStore'

/**
 * A pinia plugin that returns defined dummyvalues when Dummy mode is active
 * @param {options, store} See https://pinia.vuejs.org/core-concepts/plugins.html#introduction
 * @returns
 */
const dummyPlugin: PiniaPlugin = ({ options, store }) => {
  if (options.dummyValue !== undefined && uiOptions().dataMode === DataMode.DUMMY) {
    return Object.keys(options.dummyValue).reduce((actions, action) => {
      actions[action] = function (...args: unknown[]) {
        const dummy = options.dummyValue![action]
        return typeof dummy === 'function' ? dummy(store, ...args) : dummy
      }
      return actions
    }, {} as Record<string, any>)
  }
  return options.actions
}

const waitForAuthentication = (fn: Function) => {
  return async (...args: any[]) => {
    const authStore = useAuthStore(pinia)
    await authStore.whenAuthenticated()
    console.info('authentication completed')
    return fn.call(null, ...args)
  }
}

/**
 *
 * @param param0
 * @returns
 */
const authListener: PiniaPlugin = ({ options, store }) => {
  if (options.authenticated !== undefined) {
    return Object.keys(options.authenticated)
      .filter((name) => options.authenticated![name])
      .reduce((wrappedMethods, method) => {
        wrappedMethods[method] = waitForAuthentication(store[method])
        return wrappedMethods
      }, {} as Record<string, any>)
  }
}

export const pinia = createPinia().use(dummyPlugin).use(authListener)

export * from './system/systemStore'
export * from './fleet/fleetStore'
export * from './usermanagement/userManagementStore'
export * from './usermanagement/members/membersStore'
export * from './usermanagement/licenses/licensesStore'
export * from './organizations/organisationsStore'
export * from './serviceIssue/serviceIssueStore'
export * from './packageInformationLicense/packageInformationLicenseStore'
export * from './internal/internalStore'
export * from './arms/armStore'
