import { ISidebar } from '@/interfaces'
import { internalLink } from '@/utils/helpers'
import { useRouter } from 'vue-router'

export function internalSidebar(): ISidebar {
  const router = useRouter()

  return {
    id: 'fruitcoreIntern',
    title: 'messages.sidebars.internal.title',
    sections: [
      {
        id: 'production',
        title: 'Inbetriebnahme',
        handler: internalLink('InternalProduction'),
        enabled: true,
      },
      {
        id: 'packaging',
        title: 'Zusammenstellung',
        handler: internalLink('InternalPackaging'),
        enabled: true,
      },
      {
        id: 'shipping',
        title: 'Versand',
        handler: internalLink('InternalShipping'),
        enabled: true,
      },
      {
        id: 'hControlCreation',
        title: 'hControl Hinzufügen',
        handler: internalLink('InternalHControl'),
        enabled: true,
      },
      {
        id: 'robotArmReuse',
        title: 'Roboterarm wiederverwenden',
        handler: internalLink('InternalRobotArmReuse'),
        enabled: true,
      },
    ],
  }
}
