import { injectPermissions } from '@/components/Permissions/roles'
import { oidcEntity } from '@/oidc'
import {
  injectFCUVersion,
  injectHfxVersions,
  injectLicenses,
  injectMembers,
  injectOrganisation,
  injectPackageInformationLicenses,
  injectServiceIssue,
  injectServiceIssues,
  injectSystem,
  injectSystems,
} from '@/stores'
import { isUserManagementEnabled } from '@/utils/flags'
import { overviewSidebar, internalSidebar, licenseSidebar, serviceSidebar } from '@/utils/Sidebars'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useToast } from 'vue-toastification'
import { authenticated, internalUser } from './authenticationConfigs'
import { checkSidebar, createAuthenticate, createLanguageQueryCheck, isMobile, sendToMobileView } from './utils'

const toast = useToast()

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/components/Home/Home.vue'),
  },
  {
    path: '/mobile',
    name: 'HomeMobile',
    component: () => import(/* webpackChunkName: "homeMobile" */ '@/components/Home/HomeMobile.vue'),
  },
  {
    path: '/fleet',
    name: 'Fleet',
    component: () => import(/* webpackChunkName: "fleet" */ '@/components/Fleet/Fleet.vue'),
    meta: {
      authentication: authenticated,
    },
    beforeEnter(to, from, next) {
      injectSystems()
      next()
    },
  },
  {
    path: '/licenses',
    name: 'Licenses',
    component: () => import(/* webpackChunkName: "license" */ '@/components/Licenses/Licenses.vue'),
    meta: {
      sidebar: licenseSidebar,
      authentication: authenticated,
      withSidebar: true,
    },
    beforeEnter(to, from, next) {
      injectPackageInformationLicenses()
      next()
    },
  },

  /**
   * Single Robot System
   */
  {
    path: '/fleet/:systemId/overview',
    name: 'SystemOverview',
    component: () => import(/*webpackChunkName: "single" */ '@/components/Fleet/Single/Overview.vue'),
    meta: {
      sidebar: overviewSidebar,
      activeSidebarSection: 'overview',
      authentication: authenticated,
      withSidebar: true,
    },
    beforeEnter(to, from, next) {
      injectSystem(to.params.systemId as string).then(next)
    },
  },

  {
    path: '/fleet/:systemId/backup',
    name: 'BackupUpdate',
    component: () => import(/*webpackChunkName: "backup" */ '@/components/Fleet/Single/Files.vue'),
    meta: {
      sidebar: overviewSidebar,
      activeSidebarSection: 'backup',
      authentication: authenticated,
      withSidebar: true,
    },
    beforeEnter(to, from, next) {
      injectSystem(to.params.systemId as string).then(next)
    },
  },
  {
    path: '/fleet/:systemId/maintenance',
    name: 'Maintenance',
    component: () => import(/*webpackChunkName: "maintenance" */ '@/components/Fleet/Single/Maintenance.vue'),
    meta: {
      sidebar: overviewSidebar,
      activeSidebarSection: 'maintenance',
      authentication: authenticated,
      withSidebar: true,
    },
    beforeEnter(to, from, next) {
      injectSystem(to.params.systemId as string).then(next)
    },
  },
  {
    path: '/fleet/:systemId/internal',
    name: 'FleetInternal',
    component: () => import(/*webpackChunkName: "fleetInternal" */ '@/components/Fleet/Single/Internal.vue'),
    meta: {
      sidebar: overviewSidebar,
      activeSidebarSection: 'internal',
      authentication: authenticated,
      withSidebar: true,
    },
    beforeEnter(to, from, next) {
      injectSystems()
      injectServiceIssues()
      injectSystem(to.params.systemId as string).then(next)
    },
  },
  {
    path: '/fleet/:systemId/service',
    name: 'SingleService',
    component: () => import(/*webpackChunkName: "service" */ '@/components/Fleet/Single/Service.vue'),
    meta: {
      sidebar: overviewSidebar,
      activeSidebarSection: 'service',
      authentication: authenticated,
      withSidebar: true,
    },
    beforeEnter(to, from, next) {
      injectSystem(to.params.systemId as string).then(next)
    },
  },
  /**
   * fruitcore Internal pages
   */
  {
    path: '/internal',
    name: 'InternalMain',
    redirect: '/internal/production',
  },
  {
    path: '/internal/production',
    name: 'InternalProduction',
    component: () => import(/*webpackChunkName: "internal" */ '@/components/Internal/Production.vue'),
    meta: {
      sidebar: internalSidebar,
      activeSidebarSection: 'production',
      authentication: internalUser,
      withSidebar: true,
    },
    beforeEnter(to, from, next) {
      injectSystems()
      next()
    },
  },
  {
    path: '/internal/packaging/:systemId?',
    name: 'InternalPackaging',
    component: () => import(/*webpackChunkName: "internal" */ '@/components/Internal/ShipmentConfigurator.vue'),
    meta: {
      sidebar: internalSidebar,
      activeSidebarSection: 'packaging',
      authentication: internalUser,
      withSidebar: true,
    },
    beforeEnter(to, from, next) {
      injectSystems()
      next()
    },
  },
  {
    path: '/internal/shipping/:systemId?',
    name: 'InternalShipping',
    component: () => import(/*webpackChunkName: "internal" */ '@/components/Internal/Shipping.vue'),
    meta: {
      sidebar: internalSidebar,
      activeSidebarSection: 'shipping',
      authentication: internalUser,
      withSidebar: true,
    },
    beforeEnter(to, from, next) {
      injectSystems()
      next()
    },
  },
  {
    path: '/internal/hcontrol',
    name: 'InternalHControl',
    component: () => import(/*webpackChunkName: "internal" */ '@/components/Internal/ControlCreation.vue'),
    meta: {
      sidebar: internalSidebar,
      activeSidebarSection: 'hControlCreation',
      authentication: internalUser,
      withSidebar: true,
    },
    beforeEnter(to, from, next) {
      injectSystems()
      next()
    },
  },
  {
    path: '/internal/robotArmReuse',
    name: 'InternalRobotArmReuse',
    component: () => import(/*webpackChunkName: "internal" */ '@/components/Internal/RobotArmReuse.vue'),
    meta: {
      sidebar: internalSidebar,
      activeSidebarSection: 'robotArmReuse',
      authentication: internalUser,
      withSidebar: true,
    },
    beforeEnter(to, from, next) {
      injectSystems()
      next()
    },
  },

  /**
   * Documentation and Downloads
   */
  {
    path: '/documentation/:levelA?/:levelB?/:levelC?',
    name: 'Dokumentation & Downloads',
    component: () => import(/*webpackChunkName: "documentation" */ '@/components/Documentation/Documentation.vue'),
    meta: {
      authentication: authenticated,
    },
  },
  {
    path: '/downloads/:levelA?/:levelB?/:levelC?',
    name: 'Downloads',
    component: () => import(/*webpackChunkName: "documentation" */ '@/components/Documentation/Downloads.vue'),
  },
  {
    path: '/horstfx/options',
    name: 'Horstfx-Options',
    component: () => import(/*webpackChunkName: "horstfxoptions" */ '@/components/HorstFX/index.vue'),
    beforeEnter: (to, from, next) => {
      if (isMobile()) {
        next({
          name: 'HorstFXWeb',
        })
      } else {
        injectHfxVersions()
        injectFCUVersion()
        next()
      }
    },
  },
  {
    path: '/horstfx/options/fcu/download/:version',
    name: 'Horstfx-Options-Download-FCU',
    component: () => import(/*webpackChunkName: "horstfxoptionsdownload"*/ '@/components/HorstFX/download.vue'),
    props: (to) => ({
      fcu: true,
      version: to.params.version,
    }),
  },
  {
    path: '/horstfx/options/download/:version/:lang?',
    name: 'Horstfx-Options-Download',
    component: () => import(/*webpackChunkName: "horstfxoptionsdownload"*/ '@/components/HorstFX/download.vue'),
    props(to) {
      return {
        fcu: false,
        version: to.params.version,
        lang: to.params.lang,
      }
    },
  },
  /**
   * HorstFXWeb
   */
  {
    path: '/apps/horstfxweb',
    name: 'HorstFXWeb',
    component: () => import(/*webpackChunkName: "horstFXWeb" */ '@/components/HorstFXWeb/HorstFXWeb.vue'),
  },

  /**
   * Service
   */
  {
    path: '/service',
    name: 'Service',
    component: () => import(/* webpackChunkName: "service" */ '@/components/Service/ServiceTable.vue'),
    meta: {
      sidebar: serviceSidebar,
      activeSidebarSection: 'issues',
      authentication: authenticated,
      withSidebar: true,
    },
    beforeEnter(to, from, next) {
      injectSystems()
      injectServiceIssues()
      next()
    },
  },
  {
    path: '/service/:issueIdOrKey/details',
    name: 'ServiceIssueDetails',
    beforeEnter: (to, _, next) => {
      if (Object.keys(to.params).length === 0 || to.params.issueIdOrKey === undefined) {
        next({ name: 'Service' })
      }
      injectSystems()
      injectServiceIssue(to.params.issueIdOrKey as string).then(next)
    },
    component: () => import(/*webpackChunkName: "serviceissuedetails" */ '@/components/Service/SelectedService.vue'),
    meta: {
      sidebar: serviceSidebar,
      activeSidebarSection: 'issues',
      authentication: authenticated,
      withSidebar: true,
    },
  },
  {
    path: '/organizations',
    name: 'OrganizationsChecker',
    component: () =>
      import(/*webpackChunkName: "OrganizationsChecker" */ '@/components/Permissions/OrganizationsChecker.vue'),
    meta: {
      //sidebar: ,
      //activeSidebarSection: 'issues',
      authentication: authenticated,
      withSidebar: false,
    },
    sensitive: true,
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import(/*webpackChunkName: "Download" */ '@/components/Download/Download.vue'),
    props: (to) => ({
      filename: to.query.filename,
    }),
    meta: {
      authentication: authenticated,
      withSidebar: false,
    },
    sensitive: true,
  },
  ...(isUserManagementEnabled()
    ? [
        {
          path: '/organizations/list',
          name: 'OrganizationsList',
          component: () =>
            import(/*webpackChunkName: "OrganizationsList" */ '@/components/Permissions/OrganizationsList.vue'),
          meta: {
            authentication: authenticated,
            withSidebar: false,
          },
        } as RouteRecordRaw,
        {
          /**
           * for there it is not the case that the first id is a id of a Organization we have to check this separately
           */
          path: '/organizations/:groupIds+',
          name: 'OrganizationOverview',
          component: () => import(/*webpackChunkName: "GroupOverview" */ '@/components/Permissions/GroupOverview.vue'),
          meta: {
            authentication: authenticated,
            withSidebar: false,
          },

          beforeEnter(to, from, next) {
            const groupIds = to.params.groupIds as string[]
            Promise.all([
              injectOrganisation(...groupIds),
              groupIds.map((id) => injectMembers(id)),
              injectLicenses(groupIds[groupIds.length - 1]),
              injectPermissions(),
            ]).then(() => next())
          },
        } as RouteRecordRaw,
      ]
    : []),
]

const user = oidcEntity

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const authenticate = createAuthenticate(router, user, toast)
const checkLanguageQuery = createLanguageQueryCheck(router)

// Navigation Global Guards
router.beforeEach((to, from, next) => {
  authenticate(to)
  checkLanguageQuery(to)
  sendToMobileView(to, from, next)
})

router.afterEach((to) => {
  checkSidebar(to)
})

user.addUserChangeHandler(() => {
  // User changed Navigation check
  authenticate(router.currentRoute.value)
})

export default router
export { routes }
